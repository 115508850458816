*{
    font-family: 'Poppins', sans-serif !important; 
}
.cursor-pointer{
    cursor: pointer;
}
.bg-primary-blue {
    background: #2264ab !important;
    color: white;
}

.btn-primary-blue {
    color: #fff;
    background-color: #2264ab;
    border-color: #2264ab;
}

.btn-primary-blue:hover {
    color: #fff;
    background-color: #155497;
    border-color: #155497;
}

.card_title {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.card_title svg{
    vertical-align: middle;
}

.page-wrapper .page-body-wrapper .page-sidebar {
    background: #2264ab !important;
}

.contact-divider {
    position: relative;
    clear: both;
    height: 0;
    margin: 30px 0;
    border-top: 1px solid #eff0f1;  
}

.contact-divider::before {
position: absolute;
    top: -11px;
    left: 40%;
    margin-left: -10px;
    padding: 0 5px;
    color: #1b252a;
    background: #fff;
    content: "Contact Us";
}

.Toastify__toast--success {
    background: #f1f5f2 !important;
}

.Toastify__toast--error {
    background: #f1f5f2 !important;
}

.cursor_pointer{
    cursor: pointer;
}

.footer{
    background-color: white !important;
}